var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import Scanner from "@/components/Scanner.vue";
import TitleModal from "./modal/TitleModal.vue";
import AddModal from "./modal/AddModal.vue";
let Scan = class Scan extends Vue {
    constructor() {
        super(...arguments);
        this.kiosk = null;
    }
    add() {
        return __awaiter(this, void 0, void 0, function* () {
            const code = yield this.$refs.add.show();
            if (code != null)
                yield this.scan(code);
        });
    }
    scan(msg) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // 目前均假定得到的 URL 為 "...abcd123" 這樣的格式
            let code = (_a = msg.match(/[a-z0-9]+$/i)) === null || _a === void 0 ? void 0 : _a[0];
            if (!code)
                return yield this.alert("無法辨識 QR Code 格式", "無法辨識", "warning", "重新操作");
            if (code.match(/lp\d{8}/i))
                code = code.substr(2);
            try {
                yield post("/api/Device/Scan", { Id: code });
                if (!(yield this.confirm("裝置編號：LP" + code, "裝置綁定")))
                    return;
                var model = yield post("/api/Device/ScanBind", { Id: code });
                // await this.alert("設備綁定成功", undefined, "success");
                yield this.$refs.modal.show(model);
                this.$router.back();
            }
            catch (e) {
                if (e instanceof Error) {
                    let [t, b] = e.message.includes("：") ? e.message.split("：") : [undefined, e.message];
                    yield this.alert(b, t, "warning", "重新操作");
                }
            }
        });
    }
};
__decorate([
    Inject()
], Scan.prototype, "alert", void 0);
__decorate([
    Inject()
], Scan.prototype, "confirm", void 0);
Scan = __decorate([
    Component({ components: { TitleModal, AddModal, Scanner } })
], Scan);
export default Scan;
