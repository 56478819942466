
	import { Component, Inject, Vue } from "vue-property-decorator";
	import type { LeapaAccountModel } from "@/models/Info";

	@Component
	export default class Scan extends Vue {

		@Inject() public readonly alert!: AlertMethod;

		protected token: string = "";

		created(): void {
			post<LeapaAccountModel>("/api/Info").then(v => this.token = v.Id);
		}

		protected toWifi(): void {
			jsInterface.toWifi();
		}

		protected search(): void {
			const url = 'http://192.168.100.1';
			if(navigator.userAgent.includes("iPhone")) location.href = url;
			else this.$router.push("/device/hub/" + encodeURIComponent(url));
		}
	}
