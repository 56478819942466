var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Vue } from "vue-property-decorator";
let Scan = class Scan extends Vue {
    constructor() {
        super(...arguments);
        this.token = "";
    }
    created() {
        post("/api/Info").then(v => this.token = v.Id);
    }
    toWifi() {
        jsInterface.toWifi();
    }
    search() {
        const url = 'http://192.168.100.1';
        if (navigator.userAgent.includes("iPhone"))
            location.href = url;
        else
            this.$router.push("/device/hub/" + encodeURIComponent(url));
    }
};
__decorate([
    Inject()
], Scan.prototype, "alert", void 0);
Scan = __decorate([
    Component
], Scan);
export default Scan;
