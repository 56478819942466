var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import * as bootstrap from 'bootstrap';
import { TagType } from "@common/models/kiosk";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.message = "";
        this.code = "";
        this.options = [];
        this.tags = [];
        this.id = "";
        this.title = "";
    }
    created() {
        post('/api/tag', { Type: TagType.Kiosk }).then(v => this.options = v);
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el, { backdrop: 'static' });
    }
    show(model) {
        return new Promise(resolve => {
            this.id = model.Id;
            this.tags = [];
            this.code = model.Kiosk.DisplayCode;
            this.title = "未命名（" + this.code + "）";
            this.$el.addEventListener('hidden.bs.modal', () => resolve(), { once: true });
            this.modal.show();
        });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield post('/api/Device/Edit', {
                    Id: this.id,
                    Name: this.title,
                    Tags: this.tags.map(t => t.Id),
                });
                this.modal.hide();
            }
            catch (e) {
                yield this.alert('儲存失敗，請稍後再試', '', 'warning');
            }
        });
    }
    btnClass(o, i) {
        return (this.tags.includes(o) ? "btn-secondary" : "btn-light") + (i > 0 ? " ml-2" : "");
    }
    toggle(o) {
        const i = this.tags.indexOf(o);
        if (i >= 0)
            this.tags.splice(i, 1);
        else
            this.tags.push(o);
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
